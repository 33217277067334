import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../References/Urls'
import AuthContext from '../Store/auth-context'
import SlipGenerator from './SlipGenerator'
import styles from './SlipsHome.module.css'

const SlipsHome = () => {
    const [isGeneratorOpen, setIsGeneratorOpen] = useState(false)
    const toggleGenerator = () => setIsGeneratorOpen(false)
    const authCtx = useContext(AuthContext)
    const employeeIdentifierRecord = JSON.parse(authCtx.authRespo)
    const employeeIdentifier = employeeIdentifierRecord?.employeeIdentifier

    
    const getEmployeeDetailsForSlip = async () => {
        const getEmployeeDetailsForSlipResponse = await fetch(BASE_URL_API+"/getEmployeeDetailsForSlip",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                employeeIdentifier : employeeIdentifier
            })
        });

        if(!getEmployeeDetailsForSlipResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getEmployeeDetailsForSlipRespo = await getEmployeeDetailsForSlipResponse.json()
            if(getEmployeeDetailsForSlipRespo.status==="success") {

            } else {

            }
            console.log(getEmployeeDetailsForSlipRespo)
        }
    }

    useEffect(() => {
        getEmployeeDetailsForSlip()
    }, [])
    


    return (
        <div>
            SlipsHome
            {isGeneratorOpen && <SlipGenerator isOpen={isGeneratorOpen} toggleGenerator={toggleGenerator} />}
            <button onClick={() => setIsGeneratorOpen(true)}>Generate Slip</button>
        </div>
    )
}

export default SlipsHome