import React from 'react';
import styles from './LeavesPolicy.module.css';

const LeavesPolicy = () => {
    const policies = [
        {
            category: "Introduction",
            description:
                "This document describes the leaves, holidays, and attendance policies at Lokaci. These are subject to change as per the Management and HR Department's discretion."
        },
        {
            category: "Applicable To",
            description:
                "The policies apply to all employees of the organization, irrespective of position, department, and responsibilities."
        },
        {
            category: "Leave Types",
            types: [
                {
                    title: "Casual Leaves",
                    points: [
                        "1 paid CL per month for medical reasons and emergencies.",
                        "Unused CL lapses on 31st December of the year.",
                        "Up to 3 CLs can be taken in a month with proper approval."
                    ]
                },
                {
                    title: "Period Leaves",
                    points: [
                        "12 paid leaves annually for female employees.",
                        "Unused PLs cannot be carried forward.",
                        "1 PL per month maximum."
                    ]
                },
                {
                    title: "Maternity Leaves",
                    points: [
                        "26 weeks of paid leave for childbirth and recovery.",
                        "Inform 1 month before applying for this leave."
                    ]
                },
                {
                    title: "Bereavement Leaves",
                    points: [
                        "3 days leave in case of death in the immediate family.",
                        "Extended leave can be requested for unavoidable circumstances."
                    ]
                },
                {
                    title: "Earned Leaves",
                    points: [
                        "Earned for working on Sunday/National Holiday.",
                        "Minimum half day, maximum 3 ELs in a month."
                    ]
                },
                {
                    title: "Loss of Pay Leaves",
                    points: [
                        "Applicable when leaves do not fall under other categories.",
                        "Maximum 15 LOPs annually."
                    ]
                },
                {
                    title: "Work From Home Policy",
                    points: [
                        "Available only with management approval.",
                        "Must submit a work report by EOD."
                    ]
                }
            ]
        }
    ];

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Leaves and Holidays Policy</h1>
            {policies.map((policy, index) => (
                <div key={index} className={styles.policySection}>
                    <h2 className={styles.policyCategory}>{policy.category}</h2>
                    {policy.description && <p className={styles.policyDescription}>{policy.description}</p>}
                    {policy.types && (
                        <div className={styles.leaveTypes}>
                            {policy.types.map((type, typeIndex) => (
                                <div key={typeIndex} className={styles.leaveType}>
                                    <h3 className={styles.leaveTitle}>{type.title}</h3>
                                    <ul className={styles.leavePoints}>
                                        {type.points.map((point, pointIndex) => (
                                            <li key={pointIndex}>{point}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default LeavesPolicy;
