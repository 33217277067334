import React, { useContext, useEffect, useState } from 'react'
import { FaUser } from 'react-icons/fa'
import { BASE_URL_API } from '../../References/Urls'
import AuthContext from '../../Store/auth-context'
import styles from './ProBasicDetails.module.css'

const ProBasicDetails = () => {
    const authCtx = useContext(AuthContext)
    const authRespo = JSON.parse(authCtx.authRespo)
    const [profileData, setProfileData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const getEmployeeProfileDetails = async () => {
        setIsLoading(true)
        const getEmployeeProfileDetailsResponse = await fetch(BASE_URL_API + "/getEmployeeProfileDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    employeeIdentifier: authRespo.employeeIdentifier
                })
            });

        if (!getEmployeeProfileDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getEmployeeProfileDetailsRespo = await getEmployeeProfileDetailsResponse.json()
            if (getEmployeeProfileDetailsRespo.status === "success") {
                setProfileData(getEmployeeProfileDetailsRespo.response)
            } else {
                if (getEmployeeProfileDetailsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setProfileData(null)
                }
            }
        }
        setIsLoading(false)
    }

    useEffect(() => {
        getEmployeeProfileDetails()
    }, [])

    console.log(profileData)

    return (
        <div className={styles.rightColContainer}>
            <div className={styles.userDetailsWrapper}>
                <div className={styles.userDetailsContainer}>
                    <div className={styles.userImageWrapper}>
                        {profileData?.profilePic !== '' ?
                            <img src={profileData?.profilePic} />
                            :
                            <FaUser size={120} />
                        }
                    </div>
                    <div>
                        <div className={styles.fullname}>
                            {profileData?.fullname}
                        </div>
                        <div className={styles.designation}>
                            {profileData?.designation}
                        </div>
                        <div className={styles.department}>
                            <span className={styles.depTag}>
                                {profileData?.department}
                            </span>
                        </div>
                        <div className={styles.officialEmail}>
                            {profileData?.officialEmail}
                        </div>

                    </div>

                    <div>
                        <div>
                            {profileData?.employeeIdAtOrgLevel}
                        </div>
                    </div>
                </div>

                <div className={styles.employeeDetailsContainer}>
                    <div className={styles.edcTitle}>Personal Details</div>
                    <div className={styles.edcValueWrapper}>
                        <div>
                            <div className={styles.edcValTitle}>Date of Birth</div>
                            <div className={styles.edcValTxt}>
                                {profileData?.dateOfBirth.substring(0, 10)}
                            </div>
                        </div>
                    </div>
                </div>



                <div className={styles.employeeDetailsContainer}>
                    <div className={styles.edcTitle}>Employement Details</div>
                    <div className={styles.edcValueWrapper}>
                        <div>
                            <div className={styles.edcValTitle}>Designation</div>
                            <div className={styles.edcValTxt}>
                                {profileData?.designation}
                            </div>
                        </div>

                        <div>
                            <div className={styles.edcValTitle}>Department</div>
                            <div className={styles.edcValTxt}>{profileData?.department}</div>
                        </div>

                        <div>
                            <div className={styles.edcValTitle}>Joining Date</div>
                            <div className={styles.edcValTxt}>{profileData?.joiningDate.substring(0, 10)}</div>
                        </div>

                        <div>
                            <div className={styles.edcValTitle}>End Date</div>
                            <div className={styles.edcValTxt}>-</div>
                        </div>

                        <div>
                            <div className={styles.edcValTitle}>Employement Status</div>
                            <div className={styles.edcValTxt}>Active</div>
                        </div>
                    </div>
                </div>

                <div className={styles.employeeDetailsContainer}>
                    <div className={styles.edcTitle}>Communication Addresses</div>
                    <div className={styles.edcAdressesWrapper}>
                        <div></div>
                    </div>
                </div>

                <div className={styles.gap}>
                </div>


            </div>

            <div className={styles.statsWrapper}>
                <div className={styles.statsContainer}>
                    Attendance & Performance stats will appear here!
                </div>
            </div>
        </div>
    )
}

export default ProBasicDetails