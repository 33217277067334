import React, { useEffect, useState } from 'react';
import styles from './StickyHeader.module.css';

import { FaBars, FaCalendar, FaHome, FaRupeeSign, FaRegListAlt, FaUserAlt, FaArrowLeft } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

const StickyHeader = () => {
    const location = useLocation();
    const [pathName, setPathName] = useState('/home');
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isMobileView, setIsMobileView] = useState(false);

    useEffect(() => {
        setPathName(location?.pathname);

        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768); // Set breakpoint for mobile view
        };

        // Check initial window size
        handleResize();

        // Add resize event listener
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [location]);

    useEffect(() => {
        // Handle back button for drawer
        const handleBackButton = () => {
            if (isDrawerOpen) {
                closeDrawer(); // Close the drawer
                return true; // Prevent default back button behavior
            }
            return false;
        };

        const addHistoryState = () => {
            if (isDrawerOpen) {
                window.history.pushState(null, '', window.location.href); // Add state to history
            }
        };

        // Add event listener for popstate (back button)
        const popStateListener = () => {
            if (isDrawerOpen) closeDrawer();
        };

        window.addEventListener('popstate', popStateListener);
        addHistoryState();

        return () => {
            window.removeEventListener('popstate', popStateListener);
        };
    }, [isDrawerOpen]);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const closeDrawer = () => {
        setIsDrawerOpen(false);
    };


    useEffect(() => {
        if (isDrawerOpen) {
            const metaThemeColor = document.querySelector("meta[name='theme-color']");
            if (metaThemeColor) {
                metaThemeColor.setAttribute("content", "#ffffff");
            } else {
                const meta = document.createElement("meta");
                meta.name = "theme-color";
                meta.content = "#ffffff";
                document.head.appendChild(meta);
            }
        }
    }, [isDrawerOpen]);

    return (
        <div className={styles.mainWrapper}>
            {!isMobileView && (
                <div className={styles.navOptions}>
                    <Link to={`/home`} className={pathName === "/home" ? styles.eachNavBtnActive : styles.eachNavBtn}>
                        <FaHome /> Home
                    </Link>
                    <Link to={`/calendar`} className={pathName === "/calendar" ? styles.eachNavBtnActive : styles.eachNavBtn}>
                        <FaCalendar /> Calendar
                    </Link>
                    <Link to={`/earnings`} className={pathName === "/earnings" ? styles.eachNavBtnActive : styles.eachNavBtn}>
                        <FaRupeeSign /> Earnings
                    </Link>
                    <Link to={`/documents`} className={pathName === "/documents" ? styles.eachNavBtnActive : styles.eachNavBtn}>
                        <FaRegListAlt /> Documents
                    </Link>
                    <Link to={`/leaves-policy`} className={pathName === "/leaves-policy" ? styles.eachNavBtnActive : styles.eachNavBtn}>
                        <FaRegListAlt /> Leaves Policy
                    </Link>
                    <Link to={`/profile`} className={pathName === "/profile" ? styles.eachNavBtnActive : styles.eachNavBtn}>
                        <FaUserAlt /> Profile
                    </Link>
                </div>
            )}

            {isMobileView && (
                <>
                    <FaBars size={24} onClick={toggleDrawer} className={styles.hamburgerIcon} />
                    <div
                        className={`${styles.drawer} ${isDrawerOpen ? styles.open : ''}`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className={styles.closeWrapper}>
                            <div className={styles.closeBtn} onClick={closeDrawer}>
                                <FaArrowLeft />
                            </div>
                        </div>
                        <div className={styles.profilePicWrapper}>
                            <div className={styles.profilePicContainer} >
                                <img src="https://cdn-icons-png.flaticon.com/512/1177/1177568.png" />
                            </div>
                            <div className={styles.profileDetailsContainer}>
                                <div className={styles.pdName}>Navneet Chandan</div>
                                <div className={styles.pdProfile}>SD1</div>
                            </div>
                        </div>
                        <nav className={styles.drawerNav}>
                            <Link to={`/home`} className={pathName === "/home" ? styles.eachNavBtnActive : styles.eachNavBtn} onClick={closeDrawer}>
                                <FaHome size={21} /> Home
                            </Link>
                            <Link to={`/calendar`} className={pathName === "/calendar" ? styles.eachNavBtnActive : styles.eachNavBtn} onClick={closeDrawer}>
                                <FaCalendar size={21} /> Calendar
                            </Link>
                            <Link to={`/earnings`} className={pathName === "/earnings" ? styles.eachNavBtnActive : styles.eachNavBtn} onClick={closeDrawer}>
                                <FaRupeeSign size={21} /> Earnings
                            </Link>
                            <Link to={`/documents`} className={pathName === "/documents" ? styles.eachNavBtnActive : styles.eachNavBtn} onClick={closeDrawer}>
                                <FaRegListAlt size={21} /> Documents
                            </Link>
                            <Link to={`/leaves-policy`} className={pathName === "/leaves-policy" ? styles.eachNavBtnActive : styles.eachNavBtn} onClick={closeDrawer}>
                                <FaRegListAlt size={21} /> Leaves Policy
                            </Link>
                            <Link to={`/profile`} className={pathName === "/profile" ? styles.eachNavBtnActive : styles.eachNavBtn} onClick={closeDrawer}>
                                <FaUserAlt size={21} /> Profile
                            </Link>
                        </nav>
                    </div>
                    {isDrawerOpen && <div className={styles.overlay} onClick={closeDrawer}></div>}
                </>
            )}
        </div>
    );
};

export default StickyHeader;
