import React, { useContext, useEffect, useState } from 'react'
import StickyHeader from '../../Components/Headers/StickyHeader'
import { BASE_URL_API } from '../../References/Urls'
import AuthContext from '../../Store/auth-context'
import styles from './Profile.module.css'

import { TbLogout2 } from "react-icons/tb";
import { RiBankLine } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { FaUser } from 'react-icons/fa'
import { MdSettings } from 'react-icons/md'
import ProBasicDetails from '../../Components/User/ProBasicDetails'
import ProBankAccountDetails from '../../Components/User/ProBankAccountDetails'




const Profile = () => {
    const authCtx = useContext(AuthContext)
    const authRespo = JSON.parse(authCtx.authRespo)
    const [activeNav, setActiveNav] = useState('Basic')



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.leftColWrapper}>
                    <div className={styles.upperColWrapper}>
                        <div className={styles.eachNavWrapper} onClick={() => setActiveNav('Basic')}>
                            <div className={styles.eachNav}>
                                <div>
                                    <CgProfile color="#ffffff" size={24} />
                                </div>
                                <div className={styles.textIcon}>
                                    Details
                                </div>
                            </div>
                        </div>

                        <div className={styles.eachNavWrapper} onClick={() => setActiveNav('Bank')}>
                            <div className={styles.eachNav}>
                                <div>
                                    <RiBankLine color="#ffffff" size={24} />
                                </div>
                                <div className={styles.textIcon}>
                                    Bank
                                </div>
                            </div>
                        </div>
                        
                        <div className={styles.eachNavWrapper} onClick={() => setActiveNav('Settings')}>
                            <div className={styles.eachNav}>
                                <div>
                                    <MdSettings color="#ffffff" size={24} />
                                </div>
                                <div className={styles.textIcon}>
                                Settings
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={styles.lowerColWrapper}>
                        <div className={styles.logoutWrapper}>
                            <div onClick={() => authCtx.logout()} className={styles.logoutBtn}>
                                <div>
                                    <TbLogout2 color="#ffffff" size={24} />
                                </div>
                                <div className={styles.textIcon}>
                                    Logout
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.rightColWrapper}>
                    {activeNav==="Basic" && <ProBasicDetails />}
                    {activeNav==="Bank" && <ProBankAccountDetails />}
                </div>

            </div>
        </div>
    )
}

export default Profile