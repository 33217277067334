import React, { useContext } from 'react'
import { Routes, Route } from "react-router-dom";
import Login from '../Authentication/Login';
import StickyHeader from '../Components/Headers/StickyHeader';
import Attendance from '../Content/Attendance/Attendance';
import CHome from '../Content/Calendar/CHome';
import HolidaysCalendar from '../Content/Calendar/HolidaysCalendar';
import DocHome from '../Content/Documents/DocHome';
import EHome from '../Content/Earnings/EHome';
import AllBirthdays from '../Content/Events/AllBirthdays';
import LeavesPolicy from '../Content/LeavesPolicy/LeavesPolicy';
import Profile from '../Content/User/Profile';
import Home from '../LandingPages/Home';
import SlipGenerator from '../SalarySlips/SlipGenerator';
import SlipsHome from '../SalarySlips/SlipsHome';

import AuthContext from '../Store/auth-context';

import styles from './MainNavigator.module.css'

export const MainNavigator = () => {
    const authCtx = useContext(AuthContext)
    console.log(authCtx, "this is at main navigator")

    const isLoggedIn = authCtx.isLoggedIn

    if (!isLoggedIn) {
        return (
            <Routes>
                <Route path="*" element={<Login />}></Route>
                <Route path="/" element={<Login />}></Route>
                <Route path="/login" element={<Login />}></Route>
            </Routes>
        )
    } else {
        return (
            <div>
                <div className={styles.headerWrapper}>
                    <StickyHeader />
                </div>
                <div className={styles.contentWrapper}>
                    <Routes>
                        <Route path="*" element={<Home />}></Route>
                        <Route path="/" element={<Home />}></Route>
                        <Route path="/home" element={<Home />}></Route>
                        {/* <Route path="/salary-slips" element={<SlipGenerator />}></Route> */}
                        <Route path="/salary-slips" element={<SlipsHome />}></Route>
                        <Route path="/documents" element={<DocHome />}></Route>
                        <Route path="/profile" element={<Profile />}></Route>
                        {/* <Route path="/calendar" element={<CHome />}></Route> */}
                        <Route path="/calendar" element={<HolidaysCalendar />}></Route>
                        <Route path="/earnings" element={<EHome />}></Route>
                        <Route path="/birthdays" element={<AllBirthdays />}></Route>
                        <Route path="/attendance" element={<Attendance />}></Route>
                        <Route path="/leaves-policy" element={<LeavesPolicy />}></Route>
                    </Routes>
                </div>
            </div>
        )
    }

}
