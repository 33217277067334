import React, { useContext, useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import styles from './SlipGenerator.module.css'; // Add your CSS file for styling if needed
import { numberToWords } from '../Helper/numToTxt';
import AuthContext from '../Store/auth-context';
import AuthorizedSignatures from '../Components/SalarySlips/AuthorizedSignatures';
import { getWorkingDays } from '../Helper/jarvis-jr';

const SlipGenerator = (props) => {
    const { isOpen, toggleGenerator } = props
    const contentRef = useRef(null);
    const authCtx = useContext(AuthContext)
    const authRespo = JSON.parse(authCtx.authRespo)
    const [firmName, setFirmName] = useState('Lokaci Private Limited')
    const [firmAddress, setFirmAddress] = useState('GT - 8, Sector 117, Noida Baraula Dadri Gautam Buddha Nagar Uttar Pradesh - 201304 India')
    const [cin, setCin] = useState('U74999UP2017PTC098374')
    // GT - 8, Sector 117, Noida Baraula Dadri Gautam Buddha Nagar, Uttar Pradesh - 201304, India

    const [employeeName, setEmployeeName] = useState('Vishi')
    const [basicPay, setBasicPay] = useState(19190)
    const [hra, setHra] = useState(parseFloat(basicPay * 0.09935).toFixed(0))
    const [supplementaryAllowances, setSupplementaryAllowances] = useState(parseFloat(basicPay * 0.40645).toFixed())
    const [superAnnuationAllowances, setSuperAnnuationAllowances] = useState(2878)
    const [gratuity, setGratuity] = useState(0)
    const [pf, setPf] = useState(0)
    const [plrp, setPlrp] = useState('0')

    const [payPeriod, setPayPeriod] = useState('Oct-2022')
    const [daysWorked, setDaysWorked] = useState(25)
    const [designation, setDesignation] = useState('BOE')
    const [department, setDepartment] = useState('Operations')
    const [dateOfJoining, setDateOfJoining] = useState('06th Jun 2022')

    useEffect(() => {
        setDaysWorked(getWorkingDays(payPeriod))
    }, [payPeriod])


    const [totalEarning, setTotalEarning] = useState(parseFloat(parseFloat(basicPay) + parseFloat(hra) + parseFloat(supplementaryAllowances) + parseFloat(superAnnuationAllowances)))
    const [totalDeductions, setTotalDeductions] = useState(parseFloat(superAnnuationAllowances))
    const [finalAmt, setFinalAmt] = useState(parseFloat(totalEarning - totalDeductions))

    const generatePDF = () => {
        const input = document.getElementById('pdf-content');

        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgWidth = 210;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
                pdf.save(`${payPeriod}-${employeeName}-ss.pdf`);
            })
            .catch((error) => {
                console.log('Error generating PDF: ', error);
            });
    };

    console.log(authCtx)

    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainPageWrapper}>
                <div className={styles.pageWrapper}>
                    <div>
                        <button onClick={() => toggleGenerator()} >Close</button>
                    </div>
                    <div className={styles.rightWrapper}>
                        {/* Your HTML content to be converted to PDF */}
                        <div ref={contentRef} id="pdf-content" className={styles.mainWrapper}>
                            <div className={styles.headerWrapper}>
                                <div className={styles.firmName}>
                                    {firmName}
                                </div>
                                <div className={styles.firmAddress}>
                                    {firmAddress}
                                </div>
                                <div className={styles.hrLine}></div>
                                <div className={styles.companyDetails}>
                                    <div className={styles.cin}>
                                        <div className={styles.cinTitle}>CIN : </div>
                                        <div className={styles.cinValue}>&nbsp;{cin}</div>
                                    </div>
                                    <div className={styles.contact}>
                                        <div className={styles.cinTitle}>E-mail :</div>
                                        <div className={styles.cinValue}>&nbsp;hr@lokaci.com</div>
                                    </div>
                                </div>

                            </div>
                            <div className={styles.contentWrapper}>
                                <div className={styles.primaryHeadersWrapper}>
                                    <div className={styles.leftPrimaryHeader}>
                                        <div className={styles.eachPriHeader}>
                                            <div className={styles.ephTitle}>Date of Joining</div>
                                            <div className={styles.ephValue}>{dateOfJoining}</div>
                                        </div>
                                        <div className={styles.eachPriHeader}>
                                            <div className={styles.ephTitle}>Pay Period</div>
                                            <div className={styles.ephValue}>{payPeriod}</div>
                                        </div>
                                        <div className={styles.eachPriHeader}>
                                            <div className={styles.ephTitle}>Worked Days</div>
                                            <div className={styles.ephValue}>{daysWorked}</div>
                                        </div>

                                    </div>
                                    <div className={styles.rightPrimaryHeader}>
                                        <div className={styles.eachPriHeader}>
                                            <div className={styles.ephTitle}>Name</div>
                                            <div className={styles.ephValue}>{employeeName}</div>
                                        </div>
                                        <div className={styles.eachPriHeader}>
                                            <div className={styles.ephTitle}>Designation</div>
                                            <div className={styles.ephValue}>{designation}</div>
                                        </div>
                                        <div className={styles.eachPriHeader}>
                                            <div className={styles.ephTitle}>Department</div>
                                            <div className={styles.ephValue}>{department}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.tableContainer}>
                                    <div className={styles.tableHeader}>
                                        <div className={styles.eachBlockHeaderTitle}>
                                            Earnings
                                        </div>
                                        <div className={styles.eachBlockHeader}>
                                            Amount (₹)
                                        </div>
                                        <div className={styles.eachBlockHeaderTitle}>
                                            Deductions
                                        </div>
                                        <div className={styles.eachBlockHeader}>
                                            Amount (₹)
                                        </div>
                                    </div>
                                    <div className={styles.tableBody}>
                                        <div className={styles.eachRowWrapper}>
                                            <div className={styles.eachBlockTitle}>
                                                Basic Pay
                                            </div>
                                            <div className={styles.eachBlock}>
                                                {basicPay}
                                            </div>
                                            <div className={styles.eachBlockTitle}>
                                                Provident Fund
                                            </div>
                                            <div className={styles.eachBlock}>
                                                {pf}
                                            </div>
                                        </div>
                                        <div className={styles.eachRowWrapper}>
                                            <div className={styles.eachBlockTitle}>
                                                House Rent Allowance
                                            </div>
                                            <div className={styles.eachBlock}>
                                                {hra}
                                            </div>
                                            <div className={styles.eachBlockTitle}>
                                                PLRP
                                            </div>
                                            <div className={styles.eachBlock}>
                                                {plrp}
                                            </div>
                                        </div>
                                        <div className={styles.eachRowWrapper}>
                                            <div className={styles.eachBlockTitle}>
                                                Supplementary Allowance
                                            </div>
                                            <div className={styles.eachBlock}>
                                                {supplementaryAllowances}
                                            </div>
                                            <div className={styles.eachBlockTitle}>
                                                Superannuation Allowance
                                            </div>
                                            <div className={styles.eachBlock}>
                                                {superAnnuationAllowances}
                                            </div>
                                        </div>
                                        <div className={styles.eachRowWrapper}>
                                            <div className={styles.eachBlockTitle}>
                                                Superannuation Allowance*
                                            </div>
                                            <div className={styles.eachBlock}>
                                                {superAnnuationAllowances}
                                            </div>
                                            <div className={styles.eachBlockTitle}>

                                            </div>
                                            <div className={styles.eachBlock}>

                                            </div>
                                        </div>
                                        <div className={styles.eachRowWrapper}>
                                            <div className={styles.eachBlockTitle}>
                                                Gratuity
                                            </div>
                                            <div className={styles.eachBlock}>
                                                {gratuity}
                                            </div>
                                            <div className={styles.eachBlockTitle}>

                                            </div>
                                            <div className={styles.eachBlock}>

                                            </div>
                                        </div>
                                        <div className={styles.eachRowWrapper}>
                                            <div className={styles.eachBlockTitle}>

                                            </div>
                                            <div className={styles.eachBlock}>

                                            </div>
                                            <div className={styles.eachBlockTitle}>

                                            </div>
                                            <div className={styles.eachBlock}>

                                            </div>
                                        </div>
                                        <div className={styles.eachRowWrapper}>
                                            <div className={styles.eachBlockTitle}>
                                                Total Earnings
                                            </div>
                                            <div className={styles.eachBlock}>
                                                {totalEarning}
                                            </div>
                                            <div className={styles.eachBlockTitle}>
                                                Total Deductions
                                            </div>
                                            <div className={styles.eachBlock}>
                                                {totalDeductions}
                                            </div>
                                        </div>
                                        <div className={styles.eachRowWrapper}>
                                            <div className={styles.eachBlockTitle}>

                                            </div>
                                            <div className={styles.eachBlock}>

                                            </div>
                                            <div className={styles.eachBlockTitle}>
                                                Net Pay
                                            </div>
                                            <div className={styles.eachBlock}>
                                                {finalAmt}
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className={styles.amountWrapper}>
                                    <div className={styles.finalAmt}>₹{finalAmt}/-</div>
                                    <div className={styles.finalAmtTxt}>{numberToWords(parseInt(finalAmt))} rupees only</div>
                                </div>

                                <div className={styles.forLokaciWrapper}>
                                    <div>
                                        For
                                    </div>
                                    <div>
                                        {firmName}
                                    </div>
                                    <div>
                                        <AuthorizedSignatures />
                                        <div className={styles.underline}></div>
                                    </div>
                                </div>


                                <div className={styles.notes}>
                                    <div>Notes:</div>
                                    <ul>
                                        <li>For joining month salary compensation, please see the related clauses in the Employment Contract (EC).</li>
                                        <li>TA is given to FSE only for their Field Visits.</li>
                                    </ul>

                                </div>

                                <div className={styles.systemGenerated}>
                                    This is a system generated payslip.
                                </div>
                            </div>
                            <div className={styles.footerWrapper}>
                                <div className={styles.eachFooterSection}>
                                    https://lokaci.com/app
                                </div>
                                <div className={styles.eachFooterSection}>
                                    https://lokaci.com
                                </div>
                                <div className={styles.eachFooterSection}>
                                    https://lokaci.com/brands
                                </div>
                            </div>
                        </div>

                        <div className={styles.actionWrapper}>
                            {/* Button to generate PDF */}
                            <button onClick={generatePDF}>Generate PDF</button>
                        </div>

                    </div>
                </div>
            </div>
        )

    }

}

export default SlipGenerator