import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getHrsMinsFromDate } from '../../Helper/jarvis-jr'
import { BASE_URL_API } from '../../References/Urls'
import AuthContext from '../../Store/auth-context'
import styles from './ClockIn.module.css'
import PickLocation from './PickLocation'

const ClockIn = (props) => {
    const { isOpen, clockToggle, attendanceDate } = props
    window.onclick = event => event.target.id === "clockContainer" ? clockToggle() : null
    const authCtx = useContext(AuthContext)

    console.log("attendanceDate at clock in page : ", attendanceDate)

    let curDate = new Date().toISOString()
    let cd = curDate.substring(0, curDate.indexOf('T'))

    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');

    const hoursArr = Array.from({ length: 24 }, (_, i) => i); // Create an array of hours
    const minutesArr = Array.from({ length: 60 }, (_, i) => i); // Create an array of minutes

    const [optTime, setOptTime] = useState('IN') // OUT
    const [selectedHours, setSelectedHours] = useState(hours)
    const [selectedMinutes, setSelectedMinutes] = useState(minutes)
    const [markedTime, setMarkedTime] = useState(null)
    const [timeErrorMsg, setTimeErrorMsg] = useState('')
    const [markingClock, setMarkingClock] = useState(false)
    const [hasMarked, setHasMarked] = useState(false)


    const validateClockTime = () => {
        const nowTemp = new Date()
        const hoursTemp = nowTemp.getHours();
        const minutesTemp = nowTemp.getMinutes();

        const currentTimeStamp = parseInt(hoursTemp * 60) + parseInt(minutesTemp)
        const selectedTimeStamp = parseInt(parseInt(selectedHours) * 60) + parseInt(selectedMinutes)


        if (optTime === "IN") {
            if (selectedTimeStamp <= currentTimeStamp) {
                if (markedTime !== null) {
                    let inTimeMarkedHr = parseInt(getHrsMinsFromDate(markedTime?.inTime)?.hours)
                    let inTimeMarkedMin = parseInt(getHrsMinsFromDate(markedTime?.inTime)?.minutes)
                    let inTimeMarkedStamp = parseInt(inTimeMarkedHr * 60) + parseInt(inTimeMarkedMin)

                    let outTimeMarkedHr = parseInt(getHrsMinsFromDate(markedTime?.outTime)?.hours)
                    let outTimeMarkedMin = parseInt(getHrsMinsFromDate(markedTime?.outTime)?.minutes)
                    let outTimeMarkedStamp = parseInt(outTimeMarkedHr * 60) + parseInt(outTimeMarkedMin)

                    if (isNaN(outTimeMarkedStamp)) {
                        setTimeErrorMsg('')
                        markClockTime()
                    } else {
                        if (selectedTimeStamp <= outTimeMarkedStamp) {
                            console.log("Correct Time")
                            setTimeErrorMsg('')
                            markClockTime()
                        } else {
                            setTimeErrorMsg("In-Time can't be higher than the Out-Time")
                            console.log(outTimeMarkedStamp)
                        }
                    }
                } else {
                    console.log("Cool")
                    setTimeErrorMsg('')
                    markClockTime()
                }
            } else {
                setTimeErrorMsg("You can't select an In-Time which is in future");
            }
        } else if (optTime === "OUT") {
            if (selectedTimeStamp <= currentTimeStamp) {
                if (markedTime !== null) {
                    let inTimeMarkedHr = parseInt(getHrsMinsFromDate(markedTime?.inTime)?.hours)
                    let inTimeMarkedMin = parseInt(getHrsMinsFromDate(markedTime?.inTime)?.minutes)
                    let inTimeMarkedStamp = parseInt(inTimeMarkedHr * 60) + parseInt(inTimeMarkedMin)

                    let outTimeMarkedHr = parseInt(getHrsMinsFromDate(markedTime?.outTime)?.hours)
                    let outTimeMarkedMin = parseInt(getHrsMinsFromDate(markedTime?.outTime)?.minutes)
                    let outTimeMarkedStamp = parseInt(outTimeMarkedHr * 60) + parseInt(outTimeMarkedMin)

                    if (isNaN(inTimeMarkedStamp)) {
                        setTimeErrorMsg('')
                        markClockTime()
                    } else {
                        if (selectedTimeStamp >= inTimeMarkedStamp) {
                            console.log("correct time")
                            setTimeErrorMsg('')
                            markClockTime()
                        } else {
                            setTimeErrorMsg("Out-Time should be higher than In-Time")
                        }
                    }
                } else {
                    console.log("cool")
                    setTimeErrorMsg('')
                    markClockTime()
                }
            } else {
                setTimeErrorMsg("You can't select an Out-Time which is in the future")
            }
        } else {
            setTimeErrorMsg("Invalid Time")
        }


    }

    useEffect(() => {
        // validateClockTime()
    }, [selectedHours, selectedMinutes])



    const getClockTime = async () => {


        const getClockTimeResponse = await fetch(BASE_URL_API + "/getClockTime",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    employeeIdentifier: JSON.parse(authCtx.authRespo).employeeIdentifier,
                    attendanceDate: attendanceDate !== undefined ? attendanceDate : cd
                })
            });

        if (!getClockTimeResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getClockTimeRespo = await getClockTimeResponse.json()
            if (getClockTimeRespo.status === "success") {
                setMarkedTime(getClockTimeRespo.response)
            } else {
                if (getClockTimeRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setMarkedTime(null)
                }
            }
            console.log(getClockTimeRespo)
        }
    }

    useEffect(() => {
        if (isOpen) {
            getClockTime()
            setHasMarked(false)
        }
    }, [isOpen])

    useEffect(() => {
        setHasMarked(false)
    }, [optTime])




    const markClockTime = async () => {
        setMarkingClock(true)
        const markClockTimeResponse = await fetch(BASE_URL_API + "/markClockTime",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    organizationIdentifier: JSON.parse(authCtx.authRespo).organizationIdentifier,
                    employeeIdentifier: JSON.parse(authCtx.authRespo).employeeIdentifier,
                    clockTime: `${selectedHours}:${selectedMinutes}`,
                    visitType: optTime,
                    date: attendanceDate !== undefined ? attendanceDate : cd
                })
            });

        if (!markClockTimeResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const markClockTimeRespo = await markClockTimeResponse.json()
            if (markClockTimeRespo.status === "success") {
                getClockTime()
                setHasMarked(true)
            } else {
                if (markClockTimeRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    console.log(markClockTimeRespo)
                }
            }
        }
        setMarkingClock(false)
    }


    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <PickLocation />
                <div className={styles.mainContainer} id="clockContainer">
                    <div className={styles.mainContent}>
                        <div className={styles.mainHeader}>
                            <div className={styles.optTimeWrapper}>
                                <button className={optTime === "IN" ? styles.eachOptTimeActive : styles.eachOptTime}
                                    onClick={() => setOptTime("IN")}
                                >
                                    In-Time
                                </button>
                                <button className={optTime === "OUT" ? styles.eachOptTimeActive : styles.eachOptTime}
                                    onClick={() => setOptTime("OUT")}
                                >
                                    Out-Time
                                </button>
                            </div>
                            <div>
                                <Link to='/attendance' >See All</Link>
                            </div>
                        </div>
                        {markedTime !== null &&
                            <div className={styles.alreadyClockedInWrapper}>
                                <div className={styles.aciwRowHeaderMob}>
                                    <div className={styles.date}>
                                        <div className={styles.title}>
                                            Date</div>
                                        <div className={styles.status}>{markedTime?.attendanceDate.substr(0, 10)}</div>
                                    </div>
                                    <div className={styles.day}>
                                        <div className={styles.title}>
                                            Day</div>
                                        <div className={styles.status}>-</div>
                                    </div>
                                    <div className={styles.clockIn}>
                                        <div className={styles.title}>
                                            Clock In
                                        </div>
                                        <div className={styles.status}>
                                            {markedTime?.inTime?.substr(11, 5)}
                                        </div>
                                    </div>
                                    <div className={styles.clockIn}>
                                        <div className={styles.title}>
                                            Clock Out
                                        </div>
                                        <div className={styles.status}>
                                            {markedTime?.outTime?.substr(11, 5)}
                                        </div>
                                    </div>
                                    <div className={styles.dayStatus}>
                                        <div className={styles.title}>
                                            Status</div>
                                        <div className={styles.status}>
                                            {markedTime?.attendanceStatus || '-'}
                                        </div>
                                    </div>
                                    <div className={styles.approvalStatus}>
                                        <div className={styles.title}>
                                            Approval Status
                                        </div>
                                        <div className={styles.status}>
                                            {markedTime?.approvalStatus}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.aciwRowHeader}>
                                    <div className={styles.date}>
                                        Date
                                    </div>
                                    <div className={styles.day}>
                                        Day
                                    </div>
                                    <div className={styles.clockIn}>
                                        Clock In
                                    </div>
                                    <div className={styles.clockIn}>
                                        Clock Out
                                    </div>
                                    <div className={styles.dayStatus}>
                                        Status
                                    </div>
                                    <div className={styles.approvalStatus}>
                                        Approval Status
                                    </div>
                                </div>
                                <div className={styles.aciwRow}>
                                    <div className={styles.date}>
                                        {markedTime?.attendanceDate.substr(0, 10)}
                                    </div>
                                    <div className={styles.day}>
                                        Sunday
                                    </div>
                                    <div className={styles.clockIn}>
                                        {markedTime?.inTime?.substr(11, 5)}
                                    </div>
                                    <div className={styles.clockIn}>
                                        {markedTime?.outTime?.substr(11, 5)}
                                    </div>
                                    <div className={styles.dayStatus}>
                                        {markedTime?.attendanceStatus}
                                    </div>
                                    <div className={styles.approvalStatus}>
                                        {markedTime?.approvalStatus}
                                    </div>
                                </div>
                            </div>
                        }
                        <div>
                            {hasMarked === false ?
                                <div className={styles.markAttendanceWrapper}>
                                    <div className={styles.dialWrapper}>
                                        <div className={styles.eachDialWrapper}>
                                            <select value={selectedHours}
                                                onChange={(e) => setSelectedHours(e.target.value)}
                                            >
                                                {hoursArr.map((hour) => (
                                                    <option key={hour} value={hour.toString().padStart(2, '0')}>
                                                        {hour.toString().padStart(2, '0')}
                                                    </option>
                                                ))}
                                            </select>
                                            hr
                                        </div>
                                        <div className={styles.eachDialWrapper}>
                                            <select value={selectedMinutes}
                                                onChange={(e) => setSelectedMinutes(e.target.value)}
                                            >
                                                {minutesArr.map((minute) => (
                                                    <option key={minute} value={minute.toString().padStart(2, '0')}>
                                                        {minute.toString().padStart(2, '0')}
                                                    </option>
                                                ))}
                                            </select>
                                            min
                                        </div>
                                    </div>
                                    <div className={styles.msgTxt}>
                                        By default, it picks the current time, however you can change the time by clicking on the values.

                                    </div>
                                    <div className={styles.checkInBtnWrapper}>
                                        <div className={styles.timeErrorMsg}>
                                            {timeErrorMsg}
                                        </div>
                                        {markingClock === true ?
                                            <button className={styles.checkInBtn} >Marking {optTime}-Time</button>
                                            :
                                            <button className={styles.checkInBtn} onClick={() => validateClockTime()} >Clock {optTime}</button>
                                        }
                                    </div>


                                </div>
                                :
                                <div className={styles.markAttendanceWrapper}>
                                    <div className={styles.attendanceTxt}>
                                        Attendance Marked Successfully
                                    </div>

                                    <button className={styles.doneBtn}
                                        onClick={() => clockToggle()}
                                    >
                                        Done
                                    </button>
                                </div>
                            }


                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ClockIn