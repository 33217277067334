import React from 'react';
import styles from './HolidaysCalendar.module.css';

const holidaysData = {
    January: [{ date: "26", name: "Republic Day" }],
    February: [],
    March: [{ date: "8", name: "Holi" }],
    April: [{ date: "14", name: "Ambedkar Jayanti" }],
    May: [{ date: "1", name: "Labour Day" }],
    June: [],
    July: [],
    August: [
        { date: "15", name: "Independence Day" },
        { date: "29", name: "Raksha Bandhan" },
    ],
    September: [{ date: "19", name: "Ganesh Chaturthi" }],
    October: [
        { date: "2", name: "Gandhi Jayanti" },
        { date: "24", name: "Dussehra" },
    ],
    November: [{ date: "12", name: "Diwali" }],
    December: [{ date: "25", name: "Christmas" }],
};

const HolidaysCalendar = () => {
    return (
        <div className={styles.holidaysCalendar}>
            {Object.keys(holidaysData).map((month) => (
                <div key={month} className={styles.monthContainer}>
                    <h3 className={styles.monthTitle}>{month}</h3>
                    {holidaysData[month].length > 0 ? (
                        holidaysData[month].map((holiday, index) => (
                            <div key={index} className={styles.holidayItem}>
                                <span className={styles.holidayDate}>
                                    {holiday.date}
                                </span>
                                <span className={styles.holidayName}>
                                    {holiday.name}
                                </span>
                            </div>
                        ))
                    ) : (
                        <div className={styles.noHolidays}>No holidays</div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default HolidaysCalendar;
