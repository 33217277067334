import React, { useState } from 'react';
import styles from './PickLocation.module.css';

const fixedLocation = { latitude: 12.837048, longitude: 77.664231 };

// 12.837048, 77.664231 // tata power

const PickLocation = () => {
    const [userLocation, setUserLocation] = useState(null);
    const [error, setError] = useState(null);
    const [isWithinRadius, setIsWithinRadius] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(true)

    // Function to calculate distance between two coordinates
    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const toRadians = (degree) => (degree * Math.PI) / 180;
        const R = 6371; // Earth's radius in kilometers
        const dLat = toRadians(lat2 - lat1);
        const dLon = toRadians(lon2 - lon1);

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadians(lat1)) *
            Math.cos(toRadians(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // Distance in kilometers

        return distance;
    };

    const fetchLocation = () => {
        if (!navigator.geolocation) {
            setError('Geolocation is not supported by your browser.');
            return;
        }

        setLoading(true);
        setError(null);

        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                setUserLocation({ latitude, longitude });

                // Check if the user's location is within 0.5 km of the fixed location
                const distance = calculateDistance(
                    latitude,
                    longitude,
                    fixedLocation.latitude,
                    fixedLocation.longitude
                );
                setIsWithinRadius(distance <= 1);
                setLoading(false);
            },
            (err) => {
                if (err.code === 1) {
                    setError(
                        'Permission denied. Please enable location access in your browser or device settings.'
                    );
                } else if (err.code === 2) {
                    setError('Location unavailable. Please try again.');
                } else if (err.code === 3) {
                    setError('Location request timed out. Please try again.');
                } else {
                    setError('An unknown error occurred.');
                }
                setLoading(false);
            }
        );
    };

    const requestPermissionAgain = () => {
        if (error === 'Permission denied. Please enable location access in your browser or device settings.') {
            alert('Please enable location access from your browser or device settings.');
        } else {
            fetchLocation();
        }
    };

    if (!isOpen) {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.title}>Pick Your Location</div>
                    <button className={styles.button} onClick={fetchLocation} disabled={loading}>
                        {loading ? 'Fetching Location...' : 'Fetch Current Location'}
                    </button>
                    {loading && <p className={styles.loading}>Please wait, fetching your location...</p>}
                    {userLocation && !loading && (
                        <div className={styles.info}>
                            <p>Latitude: {userLocation.latitude}</p>
                            <p>Longitude: {userLocation.longitude}</p>
                            {isWithinRadius ? (
                                <button className={styles.attendanceButton} onClick={() => setIsOpen(false)} >Mark Attendance</button>
                            ) : (
                                <p className={styles.proximityMessage}>
                                    You must be within the office proximity to mark your attendance.
                                </p>
                            )}
                        </div>
                    )}
                    {error && (
                        <div className={styles.error}>
                            <p>{error}</p>
                            <button className={styles.button} onClick={requestPermissionAgain}>
                                Request Permission Again
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PickLocation;
